<template>
    <div class="is-block" style="width:100%">
        <vue-tel-input v-model="display" defaultCountry="US" :styleClasses="'input'" @on-input="InputEvent" @country-changed="CountryChangedEvent" @blur="() => pristine = false" ref="field">
        </vue-tel-input>
        <p class="is-danger help" v-show="error && !pristine">
            <slot name="error">The phone number is invalid</slot>
        </p>
    </div>
</template>
<script>
    import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
    export default {
        props: {
            modelValue: {
                type: String,
                default: ""
            },
        },
        emits: ["update:modelValue"],
        setup(props, context) {
            const loading = ref(true);
            const error = ref(false);
            const pristine = ref(true);
            const display = ref("");
            const model = ref("");
            const field = ref();

            const UpdateModel = () => {
                context.emit("update:modelValue", model.value);
            };

            const InputEvent = (number, phoneObj) => {
                display.value = phoneObj?.formatted;
                model.value = phoneObj?.number;
                if (phoneObj.valid === true) {
                    error.value = false;
                    UpdateModel();
                }
                else {
                    error.value = true;
                }
            }

            const CountryChangedEvent = (countryObj) => {
                if (loading.value === true) {
                    loading.value = false;
                    display.value = props.modelValue || "";
                    field.value.phone = props.modelValue || "";
                }
                else {
                    //
                    UpdateModel();
                }
            }

            onMounted(() => {
                display.value = props.modelValue || "";
                field.value.phone = props.modelValue || "";
            });

            return {
                field,
                loading,
                error,
                pristine,
                model,
                display,
                UpdateModel,
                InputEvent,
                CountryChangedEvent
            };
        }
    }
</script>